import React, { useRef, useState } from 'react';
import'../../styles/vet-style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateLeft, faStar,faQuoteLeft, faCreditCard, faDollarSign, faMobileButton, faShield, faUsers } from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout';
import { Link } from 'gatsby';
import Modal from '../../components/Modal';
import { CSSTransition } from 'react-transition-group';

function Index(props) {
    const learnMoreRef = useRef(null)
    const executeScroll = () =>learnMoreRef.current.scrollIntoView({behavior:'smooth'})
    let styles ={
        section2:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649787709/cwa/assets/images/Veterinary/dog-brown_xk6lfp.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        section4:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649787705/cwa/assets/images/Veterinary/Testimonials-BG-e1536608914568_ceh3jt.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        section6:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649787702/cwa/assets/images/Veterinary/dog-black-2_fh03ci.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        hero:{
            position:'relative',
            minHeight:'700px',
            width:'100%',
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1659714804/new-cwa/Vets/browndog_vnar1a.png')",
            backgroundPosition:'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover',
            backgroundBlendMode:'multiply'    
        },
        about:{
            // backgroundColor: "rgba(0,0,0,.5)",
            backgroundBlendMode: "multiply",
            backgroundImage: "url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651177087/new-cwa/Vets/Testimonials-BG-e1536608914568_mbph2t.png')",
            backgroundPosition: "center", 
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            clipPath:'polygon(200px 0px, 0 100%, 100% 100%, 100% 0)'
            
        },
    }
    const [modalOpen, setModal] = useState(false)
    const closeModal = () => {
        setModal(false)
    }
    
    return (
        <div>
            <Layout location={'Veterinarians'} title={'Veterinarians'}>
            <CSSTransition in={modalOpen} timeout={300} classNames="fade-up" unmountOnExit appear> 
                <Modal closeFn={closeModal} />
            </CSSTransition>
            <svg width="0" height="0">
                <linearGradient id="bg" gradientTransform="rotate(90)">
                <stop offset="70%"  stop-color="#0099FF" />
                <stop offset="100%" stop-color="#096FAF" />
                </linearGradient>
            </svg>
            <svg width="0" height="0">
                <linearGradient id="gg" gradientTransform="rotate(90)">
                <stop offset="70%"  stop-color="#14CF74" />
                <stop offset="100%" stop-color="#049850" />
                </linearGradient>
            </svg>
            <section className="h-1/2 w-full flex bg-blue-400 lg:bg-gray-50" style={styles.hero}>
                <div className="m-auto section ">
                    <div className='px-5 lg:w-1/2 text-white'>
                        <h1 className="font-bold mb-3 leading-none" style={{fontSize:'55px'}}>Sit, Stay, Swipe & Save!</h1>
                        <p className="font-opensans leading-1">
                            We offer a broad range of credit card processing solutions that enable your clients to pay you using their preferred methods. 
                            Not only will services like online billing and recurring payments help your customers commit to treatment, but these features 
                            will also optimize your day-to-day operations so that you can focus on providing excellent care and treatment to your pets.
                        </p>
                        <button onClick={executeScroll} className="mt-5 rounded hero-button text-lg">Learn More</button> 
                    </div>                   
                </div>             
            </section>

            <section ref={learnMoreRef} className='relative paymentsolutions-section'>
                <div className="section mx-auto px-5 py-20 w-100 overflow-hidden">
                    <div className="lg:w-1/2 text-center mx-auto mb-10">
                        <p className="text-sm subtitle uppercase cw-text-gray">what we offer</p>
                        <h2 className="section-title font-bold mb-3">Payment Solutions We Offer</h2>
                        <p>Discover our cost-effective credit card processing, innovative payment solutions and round-the-clock support</p>
                    </div>
        
                    {/* <img alt='' className="z-0 absolute -right-52 overflow-hidden" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650993719/new-cwa/Vets/Lane_3000_ch82co.png'/> */}               
                    
                    <div className="z-50 grid grid-cols-1 md:col-span-2 lg:grid-cols-3 gap-10">
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faUsers} /></div>
                                {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faUsers} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Customer Vault</h3>
                                <p className="text-sm leading-6" >Allows you to store the customer's cardholder information to make billing the customer secure and easy for future sales</p>
                            </div>
                        </div>
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faCreditCard} /></div>
                                {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faCreditCard} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Card-Present Solutions</h3>
                                <p className="text-sm leading-6" >Our card readers are also EMV Chip compatible so they will not need to be replaced as chip cards become the norm.</p>
                            </div>
                        </div>
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faArrowRotateLeft} /></div>
                                {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faArrowRotateLeft} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Recurring Billing</h3>
                                <p className="text-sm leading-6" >Gives you the flexibility to automatically charge a client a specific amount on a prearranged schedule.</p>
                            </div>
                        </div>
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faShield} /></div>
                                {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faShield} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Wellness Plan</h3>
                                <p className="text-sm leading-6" >Store the customer's cardholder information to make billing the customer secure and easy</p>
                            </div>
                        </div>
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faMobileButton} /></div>
                                {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faMobileButton} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Mobile Device</h3>
                                <p className="text-sm leading-6" >Allows you to take payments anywhere (i.e., Mobile Vets, House Calls, etc)</p>
                            </div>
                        </div>
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="bg-blue-50 rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-bluegradient" icon={faDollarSign} /></div>
                                {/* <span><FontAwesomeIcon className="bg-blue-50 rounded-full p-2 w-8 h-8 text-xl cw-text-bluegradient" icon={faDollarSign} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Electronic Invoicing</h3>
                                <p className="text-sm leading-6" >Provides the convenience to your customers to pay their invoice online</p>
                            </div>
                        </div>
                    </div>
                
                </div>
                </section>
                <section>
                    <div className='section mx-auto px-5 lg:px-10 py-20 w-100 bg-blue-50 rounded-lg lg:text-left text-center'>
                        <div className='grid grid-cols-1 lg:grid-cols-2'>
                            <div className="my-auto lg:pr-5">
                                {/* <p className="text-sm subtitle uppercase">OUR STORY</p> */}
                                <h2 className="text-3xl font-bold mb-3">CWA Virtual Gateway</h2>
                                <p>Our proprietary gateway allows you to charge clients either face-to-face and the ability to check-out in the exam rooms.</p>
                                <Link to="/merchants/cwa-gateway"><button className="my-5 rounded hero-button text-lg">Learn More</button> </Link>
                            </div>
                            <div style={{padding:'56.25% 0 0 0',position:'relative'}}><iframe src="https://player.vimeo.com/video/200903640?h=5a8fdaac45&title=0&byline=0" style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>                        </div>
                        
                    </div>
                </section>
                <section className='relative zcp-section'>
                    <div className='relative h-screen' style={{width:"100%"}}>
                        {/* <img alt='' style={{height:"800px", width:"auto"}} className="absolute -ml-24 top-20 z-0 hidden lg:block" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650996046/new-cwa/Vets/image5_e6ndjf.png'/> */}
                        <div className='absolute z-1 px-5 lg:w-1/3' style={{margin:'auto',left:0,right:0,top:'35%',bottom:0,textAlign:'center'}}>
                            <h2 className="text-3xl font-bold mb-3">Zero Cost Processing For Vets</h2>
                            <p>Eliminate <b><u>over 90% </u></b> of your annual processing fees by switching to ZCP. Some merchants do not pay any fees annually.</p>
                            <button onClick={()=>setModal(true)} className="mt-5 rounded hero-button text-lg">Learn More</button>                          
                        </div> 

                    </div>     
                </section>
                <section className="cw-bg-bluegradient ">
                <div className="w-100 mx-auto px-5 relative py-20">
                    <div className="text-white section mx-auto">
                    <div className="lg:w-7/12">
                        
                        <h2 className="text-3xl font-bold mb-5">Why Veterinarians Choose CWA</h2>
                        <ul className='font-semibold space-y-2 list-disc pl-5 text-lg' style={{columns:2}}>
                            <li>Veterinary industry experts</li>
                            <li>Interface with ALL practice management software</li>
                            <li>Customizable payment solutions</li>
                            <li>Competitive Rates</li>
                            <li>All processing services are handled in-house</li>
                            <li>Dedicated Veterinary Payment Specialists</li>
                        </ul>
                        
                    </div>
                    </div>
                    <div className="hidden lg:block w-5/12 h-full absolute top-0 right-0" style={styles.about}></div>
                </div>
                </section>

                <section className='relative partners-section'>
                    {/* <img alt='' className='absolute hidden sm:hidden md:hidden lg:block' style={{height:"500px", width:'auto', bottom:"10px", right:'0px'}} src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651090360/new-cwa/Vets/catcropped_hurftp.png'/> */}
                    <div className='section mx-auto px-5 lg:px-10 py-20 w-100'>
                        <h2 className="text-3xl font-bold mb-3 text-center">Vet Partners & Associations We Work With</h2> 
                        <div className='grid lg:grid-cols-3 grid-cols-2 gap-5 content-center mt-20 mb-28 flex items-center'>
                            <Link to='https://sheltersunited.com/' target={'_blank'}><img alt='' className="m-auto h-24 object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1675348029/new-cwa/Vets/SheltersUnited_Final_Logo_Color_w10zst.png'/></Link>
                            <Link to='https://millervet.supply' target={'_blank'}><img alt='' className="m-auto h-24 object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086213/new-cwa/Vets/Miller-Veterinary-Supply_gk0gaq.png'/></Link>
                            <Link to='https://www.livma.org/' target={'_blank'}><img alt='' className="m-auto object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086224/new-cwa/Vets/livmalogo_c1atun.jpg'/></Link>
                            <Link to='https://vetevolve.com/' target={'_blank'}><img alt='' className="m-auto object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086235/new-cwa/Vets/vetevolve-logo-color_f4frpb.png'/></Link>
                            <Link to='https://vetmg.com/' target={'_blank'}><img alt='' className="m-auto h-24 w-auto object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086010/new-cwa/Vets/VMG-logo-web_ctemta.jpg'/></Link>
                            
                            {/* <img alt='' className="m-auto object-scale-down grayscale" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086012/new-cwa/Vets/petvet-logo_1_x7chei.png'/> */}
                            {/* <img alt='' className="m-auto object-scale-down grayscale" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086013/new-cwa/Vets/brp_owler_20190706_130921_original_zw63z9.png'/> */}
                            {/* <img alt='' className="m-auto object-scale-down grayscale" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086015/new-cwa/Vets/Encore-logo-blue_g9cgae.png'/> */}
                            <Link to='https://www.monarchbusinessconsulting.com/' target={'_blank'}><img alt='' className="m-auto object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086017/new-cwa/Vets/monach-logo_alrogl.svg'/></Link>
                            {/* <Link to='https://premiervetalliance.com/uk/services/premier-pet-care-plan/' target={'_blank'}><img alt='' className="m-auto h-24 w-auto object-scale-down grayscale " src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086019/new-cwa/Vets/premier_pet_care_plan_logo_ufw2cs.png'/></Link> */}
                            {/* <img alt='' className="m-auto object-scale-down grayscale" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651086021/new-cwa/Vets/Rarebreed-Color_xprmvu.svg'/> */}
                        </div>                      
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-5 text-gray-500 leading-6'>
                            <div className='px-10 relative border-r border-gray-200 '>
                                <FontAwesomeIcon style={{fontSize:"80px"}} className="-mb-2 z-0 opacity-25 text-blue-300" icon={faQuoteLeft}/>
                                <div className='z-1 '>
                                    <p className='italic' style={{minHeight:'130px'}}>
                                        My experience with CWA Merchant Services has been entirely positive. Their technical support is outstanding, but what I most appreciate 
                                        is that they don't charge more for “card not present” transactions. As a mobile practice, I process at least half of my credit cards this way. 
                                        CWA has saved me a ton of money.
                                    </p>
                                    <div className=''>
                                        <div className='text-yellow-400 text-xl'>
                                            <FontAwesomeIcon icon={faStar}/>
                                            <FontAwesomeIcon icon={faStar}/>
                                            <FontAwesomeIcon icon={faStar}/>
                                            <FontAwesomeIcon icon={faStar}/>
                                            <FontAwesomeIcon icon={faStar}/>
                                        </div>
                                        <p className='cw-text-primary font-semibold'>Dani Cimino, DVM - Owner</p>
                                        <p className='text-gray-400'>North Deering Veterinary Hospital</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='px-10 relative'>
                                <FontAwesomeIcon style={{fontSize:"80px"}} className="-mb-2 z-0 opacity-25 text-blue-300" icon={faQuoteLeft}/>
                                <p className='italic' style={{minHeight:'130px'}}>  
                                    We have been delighted with CWA. Their customer service is always friendly, knowledgeable, and very quick to respond. We have been 
                                    dealt with completely and professionally. I would recommend this service to anyone. Their rates are very fair, and we have saved since 
                                    joining them.
                                </p>
                                <div className=''>
                                    <div className='text-yellow-400 text-xl'>
                                        <FontAwesomeIcon icon={faStar}/>
                                        <FontAwesomeIcon icon={faStar}/>
                                        <FontAwesomeIcon icon={faStar}/>
                                        <FontAwesomeIcon icon={faStar}/>
                                        <FontAwesomeIcon icon={faStar}/>
                                    </div>
                                    <p className='cw-text-primary font-semibold'>Gina Glidden - Office Manager</p>
                                    <p className='text-gray-400'>Crossroads Mobile Vet</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            <section className="py-20 px-5 bg-gray-100">
                <h2 className="font-bold text-3xl text-center mb-10">Veterinary Conferences</h2>
               
                <div className='w-100 flex mb-10'>
                    <div className='mx-auto text-center space-y-2 font-serif'>
                        <a href="https://us.vetshow.com/chicago"><img alt='' style={{height:'200px',width:'auto'}} className="mx-auto" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1659480398/new-cwa/Vets/chicagovet_vtw94k.png'/></a>
                        <a href="https://us.vetshow.com/chicago" className='text-blue-700 font-bold'>Chicago Vet Show</a>
                        <p className=''>May 12-13, 2022 | Chicago, IL</p>
                    </div>        
                </div>
               
                <div className='w-100 flex mb-10'>
                    <div className='mx-auto text-center space-y-2 font-serif'>
                        <a href="https://www.avma.org/events/avma-convention"><img alt='' style={{height:'200px',width:'auto'}} className="mx-auto" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1659459068/new-cwa/Vets/american-veterinary-medical-association-avma-logo-vector_p0x9dd.png'/></a>
                        <a href="https://www.avma.org/events/avma-convention" className='text-blue-700 font-bold'>AVMA</a>
                        <p className=''>July 29 August 2, 2022 | Philadelphia, PA</p>
                    </div>        
                </div>
                <div className='w-100 flex mb-10'>
                    <div className='mx-auto text-center space-y-2 font-serif'>
                        <a href="https://www.openroomevents.com/vetforum-usa-2022.php"><img alt='' className="mx-auto" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1659542367/new-cwa/Vets/vetforum_gavxe0.png'/></a>
                        <a href="https://www.openroomevents.com/vetforum-usa-2022.php" className='text-blue-700 font-bold'>VetForum USA 2022</a>
                        <p className=''>September 23-24, 2022 | Nashville, TN</p>
                    </div>        
                </div>
                <div className='w-100 flex mb-10'>
                    <div className='mx-auto text-center space-y-2 font-serif'>
                        <a href="https://www.acvc.org/"><img alt='' className="mx-auto" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1659480398/new-cwa/Vets/acvc_n2hoxr.png'/></a>
                        <a href="https://www.acvc.org/" className='text-blue-700 font-bold'>ACVC</a>
                        <p className=''>October 10-12, 2022 | New York, NY</p>
                    </div>        
                </div>
                <div className='w-100 flex mb-10'>
                    <div className='mx-auto text-center space-y-2 font-serif'>
                        <a href="https://us.vetshow.com/new-york"><img alt='' className="mx-auto" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1659480398/new-cwa/Vets/newyorkvet_gm9zpg.png'/></a>
                        <a href="https://us.vetshow.com/new-york" className='text-blue-700 font-bold'>New York Vet Show</a>
                        <p className=''>November 3-4, 2022 | New York, NY</p>
                    </div>        
                </div>
                
            </section>
            <section className="cw-bg-bluegradient">
                <div className="w-100 mx-auto px-5 relative py-12 text-center space-y-5">
                <h2 className="text-white text-4xl font-semibold">Get Started with CWA Today</h2>
                <button onClick={()=>setModal(true)} className="btn-white">Request a quote</button>
                </div>
            </section>
            </Layout>
        </div>
    );
}

export default Index;